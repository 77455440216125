import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const UrologyOfIndianaKovacLandingPage = () => (
  <PhysicianLandingPage
    physician="Jason Kovac, MD"
    institution="Jason Kovac, MD"
    referralCode="KOVAC"
    physicianURL="https://www.dockovac.com/"
  />
)

export default UrologyOfIndianaKovacLandingPage
